import { Box, Container, Typography, Button, Paper } from "@mui/material"
import { ArrowForward as ArrowForwardIcon, Description as DescriptionIcon } from "@mui/icons-material"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import WelcomeCardStepper from "./WelcomeCardStepper";
import {useSoftUIController} from "../../../context";
import PropTypes from "prop-types";
import {PLATFORM_NAME} from "../../../utlis/constant";


const theme = createTheme({
    palette: {
        primary: {
            main: "#5e35b1",
            light: "#7e57c2",
            dark: "#4527a0",
        },
        secondary: {
            main: "#ec407a",
            light: "#f06292",
            dark: "#d81b60",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: 24,
                },
            },
        },
    },
})

// CSS for animations
const styles = {
    "@keyframes scan": {
        "0%": { top: 0, opacity: 0 },
        "25%": { opacity: 1 },
        "75%": { top: "100%", opacity: 1 },
        "100%": { top: 0, opacity: 0 },
    },
    "@keyframes bounce": {
        "0%, 100%": { transform: "translateY(0)" },
        "50%": { transform: "translateY(-5px)" },
    },
}

const WelcomeCard = ({ platformName = PLATFORM_NAME, handleOpenDialog}) => {
    const [controller] = useSoftUIController();
    const { username } = controller;

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ bgcolor: "#f8f9fa", minHeight: "100vh" }}>

                <Container sx={{ py: 4 }}>
                    <Paper elevation={2} sx={{ borderRadius: 3, overflow: "hidden" }}
                    >
                        <Box
                            sx={{ p: { xs: 3, md: 4 } }}
                        >
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 4 }}>

                                <Box
                                    sx={{
                                        width: "100%",
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        component="h1"
                                        sx={{
                                            mb: 2,
                                            fontWeight: "bold",
                                            fontSize: { xs: "1.5rem", md: "2rem" },
                                            background: `linear-gradient(45deg, #5e35b1, #3949ab)`,
                                            backgroundClip: "text",
                                            WebkitBackgroundClip: "text",
                                            color: "transparent",
                                            WebkitTextFillColor: "transparent",
                                            maxWidth: "600px",
                                        }}
                                    >
                                        Welcome to {platformName}
                                    </Typography>

                                    <Typography variant="body1" color="text.secondary" sx={{ mb: 3, maxWidth: "600px" }}>
                                        Hello,{" "}
                                        <Box component="span" sx={{ fontWeight: 600 }}>
                                            {username}
                                        </Box>
                                        ! Welcome to your digital transformation journey.
                                        Get started by digitizing your first document for free and unlock the full potential of Yai4Edu. Start transforming your documents today! (*)
                                    </Typography>

                                    <Button
                                        variant="contained"
                                        onClick={handleOpenDialog}
                                        endIcon={<ArrowForwardIcon />}
                                        sx={{
                                            background: `linear-gradient(45deg, #5e35b1, #ec407a)`,
                                            px: 3,
                                            py: 1,
                                            fontSize: "0.875rem",
                                            mb: 4,
                                        }}
                                    >
                                        Free trial
                                    </Button>
                                    <Typography variant="body2" color="text.secondary" sx={{ mb: 3, maxWidth: "400px", fontSize:"13px" }}>
                                        (*) For your free trial, you can process up to 66 pages if you choose to use only the OCR service , or up to 4 pages if you choose to use all our services on the same document.
                                    </Typography>
                                </Box>

                                {/* Document illustration section */}
                                <Box sx={{ position: "relative", width: "100%", maxWidth: "400px" }}>
                                    {/* Document digitization illustration */}
                                    <Paper
                                        elevation={2}
                                        sx={{
                                            p: 3,
                                            position: "relative",
                                            zIndex: 2,
                                            transform: "rotate(-2deg)",
                                            transition: "transform 0.3s ease",
                                            "&:hover": { transform: "rotate(0)" },
                                        }}
                                    >
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
                                            <Box sx={{ width: 10, height: 10, borderRadius: "50%", bgcolor: "primary.main" }} />
                                            <Box sx={{ height: 8, width: 80, borderRadius: 4, bgcolor: "#f5f5f5" }} />
                                        </Box>
                                        <Box sx={{ height: 8, width: "100%", borderRadius: 4, bgcolor: "#f5f5f5", mb: 1 }} />
                                        <Box sx={{ height: 8, width: "85%", borderRadius: 4, bgcolor: "#f5f5f5", mb: 1 }} />
                                        <Box sx={{ height: 8, width: "70%", borderRadius: 4, bgcolor: "#f5f5f5" }} />

                                        {/* Scanning effect */}
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                overflow: "hidden",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    height: 2,
                                                    width: "100%",
                                                    position: "absolute",
                                                    background: `linear-gradient(90deg, transparent, ${theme.palette.secondary.light}, transparent)`,
                                                    animation: "scan 4s linear infinite",
                                                    "@keyframes scan": styles["@keyframes scan"],
                                                }}
                                            />
                                        </Box>
                                    </Paper>

                                    {/* Digital document */}
                                    <Paper
                                        elevation={2}
                                        sx={{
                                            p: 3,
                                            position: "absolute",
                                            top: 16,
                                            right: -16,
                                            zIndex: 1,
                                            transform: "rotate(2deg)",
                                            transition: "transform 0.3s ease",
                                            "&:hover": { transform: "rotate(0)" },
                                            borderColor: theme.palette.secondary.light,
                                            borderWidth: 1,
                                            borderStyle: "solid",
                                        }}
                                    >
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
                                            <DescriptionIcon sx={{ fontSize: 16, color: "primary.main" }} />
                                            <Box sx={{ height: 8, width: 80, borderRadius: 4, bgcolor: "#f5f5f5" }} />
                                        </Box>
                                        <Box sx={{ height: 8, width: "100%", borderRadius: 4, bgcolor: "#f5f5f5", mb: 1 }} />
                                        <Box sx={{ height: 8, width: "85%", borderRadius: 4, bgcolor: "#f5f5f5", mb: 1 }} />
                                        <Box sx={{ height: 8, width: "70%", borderRadius: 4, bgcolor: "#f5f5f5" }} />
                                    </Paper>
                                </Box>

                                {/*stepper */}
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        mt: 2,
                                        position: "relative",
                                    }}
                                >
                                    <WelcomeCardStepper autoProgress={true} />
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Container>
            </Box>
        </ThemeProvider>
    )
}
WelcomeCard.propTypes = {
    platformName: PropTypes.string,
    handleOpenDialog: PropTypes.func.isRequired,
};
export default WelcomeCard