import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import { FaSave } from 'react-icons/fa';

const SaveButton = ({ handleSave, updatedText, disabled }) => {
    return (
        <Tooltip title="Save Changes">
            <span>
                <IconButton
                    onClick={handleSave}
                    disabled={disabled || updatedText.length === 0} // disable if loading or no updates
                    style={{ opacity: (disabled || updatedText.length === 0) ? 0.5 : 1 }}
                >
                    <FaSave />
                </IconButton>
            </span>
        </Tooltip>
    );
};

SaveButton.propTypes = {
    handleSave: PropTypes.func.isRequired,
    updatedText: PropTypes.array.isRequired,
    disabled: PropTypes.bool
};

export default SaveButton;
