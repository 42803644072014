import React from 'react';
import {MdOutlineBook, MdPerson, MdDateRange} from "react-icons/md";
import {Select, MenuItem} from '@mui/material';
import PropTypes from "prop-types";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import SoftBox from "../../../components/SoftBox";
import oneColumn from "assets/images/one-column.svg";
import multiColumn from "assets/images/multi-column.svg";

function MetaData({
                      inputContainerStyle,
                      inputStyle,
                      formData,
                      handleInputChange,
                      setIsFocused,
                      errors,
                      handleSelectChange,
                      onMultiColumnChange,
                      languages,
                      handleBlur,
                      isEditable,
                  }) {

    const handleTitleAndAuthorBlur = () => {
        handleBlur();
        setIsFocused(false)
    }
    return (
        <>
            <label htmlFor="title" className="label-style">
                Title <span style={{ color: errors.title ? 'red' : 'black' }}>*</span>
            </label>
            <div style={{
                ...inputContainerStyle,
                borderColor: errors.title ? 'red' : inputContainerStyle.borderColor
            }}>
                <MdOutlineBook className="icon-style" style={{ color: errors.title ? 'red' : 'inherit' }} />
                <input
                    type="text"
                    id="title"
                    name="title"
                    style={{ ...inputStyle, borderColor: errors.title ? 'red' : inputStyle.borderColor }}
                    value={formData.title}
                    onChange={handleInputChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={handleTitleAndAuthorBlur}
                    placeholder="Enter article title"
                />
            </div>
            {errors.title && <p className="error-text">{errors.title}</p>}

            {isEditable && (
                <React.Fragment>
                <label htmlFor="layout" className="label-style">Layout</label>
                <div style={{ marginLeft: "20px"}}>
                    <RadioGroup
                        row
                        name="layout"
                        value={formData.hasMulticolumn ? "multi" : "one"}
                        onChange={onMultiColumnChange}
                    >
                        <FormControlLabel
                            value="one"
                            control={<Radio/>}
                            label={
                                <>
                                    One Column <SoftBox sx={{marginLeft:"10px"}}  component="img"  src={oneColumn} width="40px"/>
                                </>
                            }
                            sx={{ marginRight: "40px" }}
                        />
                        <FormControlLabel
                            value="multi"
                            control={<Radio sx={{
                                color: "pink",
                                '&.Mui-checked': {
                                  color: "pink",
                                },
                              }}/>}
                            label={
                                <>
                                    More Columns <SoftBox sx={{marginLeft:"10px"}}  component="img"  src={multiColumn} width="40px"/>
                                </>
                            }
                        />
                    </RadioGroup>
                </div>
                </React.Fragment>
                )
            }

            <div style={{display: 'flex', gap: '20px'}}>
                <div style={{flex: 1}}>
                    <label htmlFor="author" className="label-style">Author</label>
                    <div style={inputContainerStyle}>
                        <MdPerson className="icon-style"/>
                        <input
                            type="text"
                            id="author"
                            name="author"
                            style={inputStyle}
                            value={formData.author}
                            onChange={handleInputChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={handleTitleAndAuthorBlur}
                            placeholder="Enter author's name"
                        />
                    </div>
                    {errors.author && <p className="error-text">{errors.author}</p>}
                </div>

                <div style={{flex: 1}}>
                    <label htmlFor="publicationDate" className="label-style">Publication Date</label>
                    <div style={inputContainerStyle}>
                        <MdDateRange className="icon-style" />
                        <input
                            type="text"
                            id="publicationDate"
                            name="publicationDate"
                            style={inputStyle}
                            value={formData.publicationDate}
                            onChange={handleInputChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            placeholder="Enter publication date"
                        />
                    </div>
                    {errors.publicationDate && <p className="error-text">{errors.publicationDate}</p>}
                </div>
            </div>

            <div style={{display: 'flex', gap: '20px'}}>
                <div style={{flex: 1}}>
                    <label htmlFor="sourceLanguage" className="label-style">
                        Source Language <span style={{ color: errors.sourceLanguage ? 'red' : 'black' }}>*</span>
                    </label>
                    {isEditable ? (
                        <><Select
                            id="sourceLanguage"
                            fullWidth
                            value={formData.sourceLanguage}
                            onChange={handleSelectChange}
                            name="sourceLanguage"
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Select a source language</MenuItem>
                            {languages.map((languageData) => (
                                <MenuItem key={languageData.id} value={languageData.language}>
                                    {languageData.language}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.sourceLanguage && <p className="error-text">{errors.sourceLanguage}</p>}
                        </>
                        ):(
                        <div style={inputContainerStyle}>
                    <input
                        type="text"
                        id="sourceLanguage"
                        name="sourceLanguage"
                        style={inputStyle}
                        value={formData.sourceLanguage}
                        disabled={!isEditable}
                    />
                </div>
                        )}
                </div>
                <div style={{flex: 1}}>
                    <label htmlFor="targetLanguage" className="label-style">
                        Target Language <span style={{ color: errors.targetLanguage ? 'red' : 'black' }}>*</span>
                    </label>
                    {isEditable ? (
                        <>
                    <Select
                        id="targetLanguage"
                        fullWidth
                        value={formData.targetLanguage}
                        onChange={handleSelectChange}
                        name="targetLanguage"
                        displayEmpty
                        disabled={!isEditable}
                    >
                        <MenuItem value="" disabled>Select a target language</MenuItem>
                        {languages.filter((languageData) => languageData.language !== "Ottoman").map((languageData) => (
                            <MenuItem key={languageData.id} value={languageData.language}>
                                {languageData.language}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.targetLanguage && <p className="error-text">{errors.targetLanguage}</p>}
                        </>): (
                        <div style={inputContainerStyle}>
                            <input
                                type="text"
                                id="targetLanguage"
                                name="targetLanguage"
                                style={inputStyle}
                                value={formData.targetLanguage}
                                disabled={!isEditable}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

MetaData.propTypes = {

    inputContainerStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    formData: PropTypes.object,
    handleInputChange: PropTypes.func,
    setIsFocused: PropTypes.func,
    errors: PropTypes.object,
    handleSelectChange: PropTypes.func,
    languages: PropTypes.arrayOf(PropTypes.object),
    handleBlur: PropTypes.func,
    onMultiColumnChange: PropTypes.func,
    isEditable: PropTypes.bool,
}
export default MetaData;
