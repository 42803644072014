import {useState, useRef, useEffect} from "react";
import { motion } from "framer-motion";
import { Star, X } from "lucide-react";
import {
    Box,
    Typography,
    IconButton,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    styled,
    Rating, Grid
} from "@mui/material"
import {getServices, submitDigitizationFeedback} from "../../axios-client";
import PropTypes from "prop-types";

const StyledStar = styled(Star)(({ theme, filled }) => ({
    width: 45,
    height: 45,
    transition: "all 0.6s",
    color: filled ? "#FFD700" : theme.palette.grey[300],
    fill: filled ? "#FFD700" : "none",
    strokeWidth: 1.5,
}))



const FeedbackDialog = ({ showFeedback, setShowFeedback, digitizationId, refreshList }) => {
    const [step, setStep] = useState("initial")
    const [rating, setRating] = useState(0);
    const [hoveredRating, setHoveredRating] = useState(0);
    const [comment, setComment] = useState("");
    const [serviceRatings, setServiceRatings] = useState({})
    const [services, setServices] = useState([]);
    const ratingRef = useRef(null);


    useEffect(() => {
        if (step === "serviceRating") {
            const fetchServices = async () => {
                try {
                    const response = await getServices();
                    setServices(response.data);
                } catch (error) {
                    console.error("Error fetching services:", error);
                }
            };
            fetchServices();
        }
    }, [step]);

    const handleInitialSubmit = () => {
        setStep("serviceRating")
    }

    const handleClose = () => {
        setShowFeedback(false)
        setStep("initial")
        setRating(0)
        setComment("")
        setServiceRatings({})
    }


    const handleFinalSubmit = async () => {
        try {
            const feedbackData = {
                digitization_id: digitizationId,
                rating: rating,
                comment: comment || null,
                service_ratings: serviceRatings,
            };

            await submitDigitizationFeedback(feedbackData);
            setStep("thanks");
            setTimeout(() => {
               handleClose();
               refreshList();
            }, 2000);

        } catch (error) {
            console.error("Error submitting feedback:", error);
        }
    };

    const handleStarHover = (event) => {
        if (ratingRef.current) {
            const rect = ratingRef.current.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const starWidth = rect.width / 5;
            const hoveredValue = Math.ceil(x / starWidth);
            setHoveredRating(hoveredValue);
        }
    };

    const handleStarClick = () => {
        setRating(Math.ceil(hoveredRating));
    };

    const handleMouseLeave = () => {
        setHoveredRating(0);
    };

    const StarRating = () => {
        return (
            <Box
                ref={ratingRef}
                onMouseMove={handleStarHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleStarClick}
                sx={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
            >
                {[1, 2, 3, 4, 5].map((star) => (
                    <StyledStar key={star} filled={star <= (hoveredRating || rating)} />
                ))}
            </Box>
        );
    };

    return (
        <Dialog
            open={showFeedback}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{sx: {borderRadius: 3, p: 5, fontFamily: "Inter, sans-serif",},
            }}
        >
            <DialogTitle sx={{textAlign: "center", fontWeight: 600, fontSize: "1.5rem", position: "relative", p: 1,}}>
                {step === "thanks" ? (
                    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", gap: 1,}}>
                        <motion.div
                            animate={{scale: [1, 1.2, 1], rotate: [0, 10, -10, 0]}}
                            transition={{ duration: 0.5 }}
                        >
                            <StyledStar filled sx={{bgcolor: "rgba(255, 215, 0, 0.1)", borderRadius: "50%", p: 1,}}/>
                        </motion.div>
                        <Typography variant="h6" sx={{fontFamily: "Inter, sans-serif", fontWeight: 600, fontSize: "1.5rem",}}>
                            Thank you!
                        </Typography>
                    </Box>
                ) : (
                    "How would you rate this digitization?"
                )}

                {step === "initial" && (
                    <IconButton
                        onClick={() => setShowFeedback(false)}
                        sx={{ position: "absolute", right: 0, top: 0, width: "2rem" }}
                    >
                        <X />
                    </IconButton>
                )}
                {step === "serviceRating" &&
                    <Typography variant="h6">Rate our services</Typography>
                }
            </DialogTitle>

            <DialogContent sx={{ pt: 0, px: 2, pb: 2 }}>
                {step === "initial" && (
                    <Box sx={{display: "flex", flexDirection: "column", gap: 3, alignItems: "center",}}>
                        <Typography
                            variant="body2"
                            sx={{color: "text.main", fontFamily: "Inter, sans-serif", fontWeight: 400, textAlign: "center", fontSize: "0.875rem",}}
                        >
                            Your feedback helps us improve our content
                        </Typography>

                        <StarRating />

                        <TextField
                            fullWidth
                            multiline
                            rows={2}
                            placeholder="Tell us something that keeps you coming back..."
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            sx={{
                                maxWidth: 475,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: 2,
                                    fontFamily: "Inter, sans-serif",
                                    padding: "0.5rem 0.1rem 0.5rem 0.75rem !important",
                                    "& textarea": {
                                        width: "100% !important",
                                    },
                                },
                            }}
                        />
                    </Box>
                )}

                {step === "serviceRating" && (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                        <Grid container justifyContent="center" alignItems="center" sx={{marginLeft: 0, marginTop: 0, padding: "15px"}} spacing={2}>
                            {services.length > 0 ? (
                                services.map((service) => (
                                    <Grid item xs={6} key={service.id}>
                                        <Typography variant="body2">{service.name}</Typography>
                                        <Rating
                                            name={service.name}
                                            value={serviceRatings[service.id] || 0}
                                            onChange={(event, newValue) => {
                                                setServiceRatings((prev) => ({ ...prev, [service.id]: newValue }));
                                            }}
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <Typography>Loading services...</Typography>
                            )}
                        </Grid>
                    </Box>
                )}

                {step === "thanks" && (
                    <Box sx={{ textAlign: "center", mt: 2 }}>
                        <Typography
                            variant="body2"
                            sx={{
                                color: "text.main",
                                fontFamily: "Inter, sans-serif",
                                fontSize: "15px",
                            }}
                        >
                            Your feedback helps us improve. We appreciate the time you took to send it!
                        </Typography>
                    </Box>
                )}
            </DialogContent>

            {step !== "thanks" && (
                <DialogActions>
                    <Button
                        onClick={step === "initial" ? handleInitialSubmit : handleFinalSubmit}
                        disabled={!rating && step === "initial"}
                        fullWidth
                        variant="contained"
                        sx={{ bgcolor: "#1d1d2f", color: "#fff", "&:hover": { bgcolor: "#2d2d3f" } }}
                    >
                        {step === "initial" ? "Next" : "Submit"}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );

}
FeedbackDialog.propTypes = {
    digitizationId: PropTypes.number,
    showFeedback: PropTypes.bool,
    refreshList: PropTypes.func,
    setShowFeedback: PropTypes.func,
};

export default FeedbackDialog;